<template>
  <LayoutNew>
    <h1 class="mb-0 text-center">Monthly Report</h1>
    <MonthlyReportMain />
  </LayoutNew>
</template>
<script setup>
import LayoutNew from "./LayoutNew";
import MonthlyReportMain from "@/components/MonthlyReport/MonthlyReportMain.vue";
import { useTitle } from "@vueuse/core";

useTitle("Monthly Report | CreatorShield");
</script>
