import { defineStore } from "pinia";
import { ref } from "vue";
import { GetMonthlyAssetGrossReport } from "../services/MonthlyReport/GetMonthlyAssetGrossReport";
import { GetMonthlyReport } from "../services/MonthlyReport/GetMonthlyReport";
export const useMonthlyReportStore = defineStore("monthlyReport", () => {
	const MRYear = ref("");
	const MRPagination = ref([]);
	const MRGrossYear = ref("");
	const MRGrossMonth = ref("");
	const monthlyReport = ref([]);
	const monthlyAssetGrossReport = ref([]);
	const userId = ref();
	const loader = ref(false);

	const getMonthlyReport = () => {
		loader.value = true;
		GetMonthlyReport({ UserId: userId.value, Year: MRYear.value }).then((response) => {
			monthlyReport.value = response;
			loader.value = false;
		});
	};
	const getMonthlyGrossReport = () => {
		loader.value = true;
		GetMonthlyAssetGrossReport({ UserId: userId.value, Year: MRGrossYear.value, Month: MRGrossMonth.value }).then(
			(response) => {
				monthlyAssetGrossReport.value = response;
				loader.value = false;
			}
		);
	};
	return {
		MRYear,
		MRGrossYear,
		MRGrossMonth,
		getMonthlyReport,
		getMonthlyGrossReport,
		monthlyReport,
		monthlyAssetGrossReport,
		userId,
		MRPagination,
		loader,
	};
});
