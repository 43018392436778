<template>
  <div class="table-container">
    <MDBTable hover>
      <thead>
        <tr>
          <th v-for="(item, i) in tableHeaders" :key="i" scope="col">
            {{ item.header }}
          </th>
        </tr>
      </thead>
      <tbody v-if="loader">
        <div>
          <MDBLoading icon="spinner-grow" />
        </div>
      </tbody>
      <tbody v-if="monthlyReport.length < 1">
        <tr>
          <td colspan="8">No Report Available</td>
        </tr>
      </tbody>
      <tbody v-else-if="monthlyReport.length > 0">
        <tr v-for="(item, i) in monthlyReport" :key="i">
          <td>{{ item.fullName }}</td>
          <td>{{ getMonthFullFormat(item.month) }}</td>
          <td>{{ item.year }}</td>
          <td>{{ item.partnerPercentage }}%</td>
          <td>${{ addCommaNumbers(item.adjustments.toFixed(2)) }}</td>
          <td>${{ addCommaNumbers(item.grossRevenue.toFixed(2)) }}</td>
          <td>${{ addCommaNumbers(item.handlerRevenue.toFixed(2)) }}</td>
          <td>${{ addCommaNumbers(item.netRevenue.toFixed(2)) }}</td>
        </tr>
      </tbody>
    </MDBTable>
  </div>
</template>

<script>
import { MDBTable, MDBLoading } from "mdb-vue-ui-kit";
import { useMonthlyReportStore } from "../../store/monthlyReport";
import { storeToRefs } from "pinia";
import { computed } from "@vue/runtime-core";
import { getMonthFullFormat } from "../../helpers/parseDate";
import { addCommaNumbers } from "../../helpers/addCommaNumbers";
export default {
  components: {
    MDBTable,
    MDBLoading,
  },
  setup() {
    const monthlyReportStore = useMonthlyReportStore();
    const tableHeaders = computed(() => {
      return [
        {
          header: "Username",
        },
        {
          header: "Month",
        },
        {
          header: "Year",
        },
        {
          header: "Partner Percentage",
        },
        {
          header: "Adjustments",
        },
        {
          header: "Gross Revenue",
        },
        {
          header: "Handler Revenue",
        },
        {
          header: "Net Revenue",
        },
      ];
    });
    const { monthlyReport, loader } = storeToRefs(monthlyReportStore);
    return {
      tableHeaders,
      monthlyReport,
      getMonthFullFormat,
      addCommaNumbers,
      loader,
    };
  },
};
</script>
<style lang="scss" scoped>
.table-container {
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  overflow-x: auto;
  :deep(.table) {
    margin-bottom: 0;
    caption {
      font-size: 1rem;
      padding-left: 20px;
    }
  }
}
</style>
