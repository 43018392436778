import { HttpClient } from "../HttpClient";
/**
 * * Get Monthly Asset Gross Report
 */
export const GetMonthlyAssetGrossReport = async ({ UserId, Year, Month }) => {
	try {
		const { data } = await HttpClient.get("api/revenues/MonthlyAssetGross", {
			params: {
				UserId,
				Year,
				Month,
			},
		});
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
